<template>
  <b-card>
    <title-name />
    <ordering />
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import TitleName from '@/views/Admin/Defines/Project_statuses/elements/Title.vue'
import Ordering from '@/views/Admin/Defines/Project_statuses/elements/Ordering.vue'

export default {
  name: 'DocumentTypeForm',
  components: {
    Ordering,
    BCard,
    TitleName,
  },
}
</script>
